<template>
  <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="60px"
           style="background-color: #e3e5fc;height: 27vh">
<!------------------------------------------用户名--------------------------------------------->
    <el-row style="height: 9vh">
      <el-col :span="20" :offset="2">
        <el-form-item  label="用户名" prop="userName">

          <el-input v-model="ruleForm.userName"
                    maxlength="25"
                    placeholder="请输入用户名"
                    prefix-icon='el-icon-user-solid'
                    clearable show-word-limit>
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>


    <!------------------------------------------密码---------------------------------------------->
    <el-row style="height: 9vh">
      <el-col :span="20" :offset="2">
        <el-form-item  label="密码" prop="password">
          <el-input v-model="ruleForm.password"
                    maxlength="25"
                    prefix-icon='el-icon-lock'
                    placeholder="请输入密码"
                    @keyup.enter.native="Keyup"
                    clearable show-password
                    show-word-limit>
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <!------------------------------------------按钮--------------------------------------------->
    <el-row style="height: 9vh">
      <el-col :span="12" type="flex" justify="center">
          <el-button type="success" @click="submitForm('ruleForm')">登录</el-button>
      </el-col>

      <el-col :span="12">
          <el-button type="success" @click="register()">注册</el-button>
      </el-col>

    </el-row>


  </el-form>
</template>

<script>

export default {
  created: function () {

  },

  data() {
    return {
      labelPosition: 'right',
      ruleForm: {
        userName: '',
        password: '',
      },
      rules: {
        userName: [
          {message: '请输入用户名', trigger: 'blur'},
          {min: 4, max: 25, message: '长度为4-25', trigger: 'blur'}
        ],
        password: [
          {message: '请输入密码', trigger: 'blur'},
          {min: 8, max: 30, message: '长度为8-30', trigger: 'blur'}
        ],
      }
    }
  },
  methods: {
    Keyup(){
      this.submitForm('ruleForm');
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {

          this.$axios.post('/api/login', this.ruleForm).then((res) => {

            if (res.data.code === 200) {
              this.$message({
                message: '登录成功',
                type: 'success'
              });
              let userInfo = res.data
              localStorage.setItem('userInfo', JSON.stringify(userInfo))

              localStorage.setItem('Authorization', userInfo.data.token)

              setTimeout(() => {
                this.$router.push({path: '/prompt/index'});
              }, 1000);

            } else {
              this.$message({
                message: res.data.msg,
                type: 'error'
              });


            }
          });

        } else {
          return false;
        }
      });
    },

    register() {
      this.$router.push({path: '/register'});
    }
  }
}
</script>

<style scoped>

/deep/ .el-button{
  background-color: rgba(103, 201, 81, 0.66);
  /*#8fbc8f;*/
  border-color: #8fbc8f;
  border-radius: 11px;
  padding: 12px 24px;
}
:deep .el-input__inner{
  background-color: #f1f3ff;
}
</style>