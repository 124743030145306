<template>
  <div style="height: 99vh ;background-color:#e2e5fb"
       v-loading="loading"
       element-loading-text="绘图中请稍等..."
       element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(0, 0, 0, 0.2)">
    <!--头部-->
    <el-row class="my-header" style="height: 7vh" type="flex" align="middle">
      <el-col :span="6">
        <el-button
            type="primary"
            icon="el-icon-arrow-left"
            size="small"
            @click="goBack_btn">返回首页
        </el-button>
      </el-col>

      <el-col :span="12">
        <label class="my-title">
          Prompt Paradise
        </label>
      </el-col>

      <el-col :span="6">
        <el-button
            type="primary"
            icon="el-icon-coin"
            size="small"
            @click="pay_btn">{{ money }}
        </el-button>
      </el-col>
    </el-row>

    <h2 style=";margin-top:1vh;margin-bottom: 1vh">Ai 绘图</h2>

    <el-row id="auto-row-Prompt">
      <el-col :offset="2" :span="2" class="col-label">
        提示词(Prompt)
      </el-col>

      <el-col :offset="2" :span="16" style="margin-bottom: 2vh;">
        <el-input
            v-model="inputPrompt"
            v-resize="resize"
            style="border-radius: 30px;width: 95%"
            type="textarea"
            :rows="5"
            placeholder="请用具体而细节的词语,描述出你心中的美妙画面!(如:一个充满遗憾的冬天,吹着雪,一只超级可爱的皮克斯风格的白色精灵狗,闪亮的白色蓬松,明亮的眼睛,蓬松的尾巴,穿着粉色毛衣,戴着粉色帽子,微笑,精致,童话般。注:英文会好于中文)"
            :maxlength="500"
            show-word-limit
        ></el-input>
      </el-col>
    </el-row>

    <el-row id="auto-row-Exclude">

      <el-col :offset="2" :span="2" class="col-label">
        排除词(Exclude)
      </el-col>

      <el-col :offset="2" :span="16" style="margin-bottom: 2vh;">
        <el-input
            v-model="inputExclude"
            v-resize="resize2"
            style="border-radius: 30px;width: 95%"
            type="textarea"
            :rows="2"
            placeholder="请输入不想出现在画中的否定提示词(如:灰色的天空,路人,太过鲜艳的颜色,藏獒...)"
            :maxlength="300"
            show-word-limit
        ></el-input>
      </el-col>
    </el-row>

    <el-row>

      <el-col :offset="5" :span="14" style="margin-bottom: 2vh">
        <el-select v-model="style" placeholder="请选择绘画风格" style="width: 40vw">
          <el-option
              v-for="item in styleList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-col>
    </el-row>

    <el-button type="success" round @click="ready" style="margin-bottom: 2vh">点击开始画图</el-button>

    <el-row>
      <viewer>
        <img style="width: 30vh; height: 30vh;margin-bottom: 1vh" :src="image">
      </viewer>
      <el-button type="success" @click="ready">重新生成</el-button>
      <el-button type="info" @click="downloadImage">查看高清大图或保存</el-button>
    </el-row>

  </div>
</template>

<script>
export default {
  name: "painting",
  data() {
    return {
      userinfo: [],
      money: 0,
      inputPrompt: '',
      inputExclude: '',
      loading: false,
      styleList: [],
      style: '',
      image: ''
    }
  },
  mounted() {
    document.body.style.overflow = 'hidden';//禁止页面滚动
    document.body.style.height= '90vh';

  },
  created() {
    this.userinfo = JSON.parse(localStorage.getItem('userInfo')).data;
    this.money = this.userinfo.money;
    this.initStyleList();
    this.initImg();
    window.mydata = this._data;//将变量全局化,这样浏览器可以查看当前data是什么情况::myData._data
  },
  methods: {
    async ready() {
      //检测用户是否登录
      if (this.userinfo == null) {
        this.$message({
          message: '请先登录',
          type: 'warning'
        });
        //延时2秒跳转到登录页面
        setTimeout(() => {
          this.$router.push("/");
        }, 1500)
        return;
      }

      //判断输入是否为空
      if (this.inputPrompt == null || this.inputPrompt == "") {
        this.$message({
          message: '输入内容不能为空',
          type: 'warning'
        });
        //强制退出该函数,不执行后续代码
        return;
      }

      //判断风格是否为空
      if (this.style == null || this.style == "") {
        this.$message({
          message: '请选择绘画风格',
          type: 'warning'
        });
        //强制退出该函数,不执行后续代码
        return;
      }

      //判断余额是否够
      this.update_money(this.userinfo.id, this.userinfo.token);

      if (this.money < 2000) {
        this.$message({
          message: '金币不足,请点击左上角金币前往充值',
          type: 'error'
        });
        this.loading = false;
        return;
      }

      //开始画图
      await this.painting();
    },

    async painting() {
      let questionJson = {
        "prompt": this.inputPrompt,
        "negative_prompts": this.inputExclude,
        "style": this.style == "" || this.style == "默认风格" ? "null" : this.style,
        "steps": 80,
        "sampler": "ddim"
      }

      this.loading = true;

      //显示开始绘图
      this.$message({
        message: '开始绘图',
        type: 'success'
      });

      await this.$axios.post('/api/single/painting', questionJson,
          {
            headers: {"Authorization": this.userinfo.token}
          }
      )
          .then((res) => {
            if (res.data.code === 200) {
              this.$message({
                message: '画图成功',
                type: 'success'
              });

              //退出loading状态
              this.loading = false;

              this.image = res.data.data;
              //1秒后提示扣费
              setTimeout(() => {
                this.$message({
                  message: '本次消耗金币2500',
                  type: 'success'
                });
              }, 1000)
            } else {
              this.$message({
                message: res.data.msg,
                type: 'error'
              });
              this.loading = false;
              this.update_money(this.userinfo.id, this.userinfo.token);
            }
          })


    },


    //下载图片
    downloadImage() {
      const link = document.createElement('a');
      link.href = this.image; // imageData为base64格式的图片数据
      link.download = this.userinfo.nickName + '.png'; // 下载的文件名
      link.click();
    },

    //初始化风格列表
    initStyleList() {
      const data = '默认风格,国画, 写实主义, 虚幻引擎, 黑白插画, 版绘, 低聚, 工业霓虹, 电影艺术, 史诗大片, 暗黑, 涂鸦, 漫画场景, 特写, 儿童画, 油画, 水彩画, 素描, 卡通画, 浮世绘, 赛博朋克, 吉卜力, 哑光, 现代中式, 相机, CG渲染, 动漫, 霓虹游戏, 蒸汽波, 宝可梦, 火影忍者, 圣诞老人, 个人特效, 通用漫画, Momoko, MJ风格, 剪纸, 齐白石, 张大千, 丰子恺, 毕加索, 梵高, 塞尚, 莫奈, 马克·夏加尔, 丢勒, 米开朗基罗, 高更, 爱德华·蒙克, 托马斯·科尔, 安迪·霍尔, 新海诚, 倪传婧, 村上隆, 黄光剑, 吴冠中, 林风眠, 木内达朗, 萨雷尔, 杜拉克, 比利宾, 布拉德利, 普罗旺森, 莫比乌斯, 格里斯利, 比普, 卡尔·西松, 玛丽·布莱尔, 埃里克·卡尔, 扎哈·哈迪德, 包豪斯, 英格尔斯, RHADS, 阿泰·盖兰, 俊西, 坎皮恩, 德尚鲍尔, 库沙特, 雷诺阿';
      this.styleList = data.split(',').map(item => ({
        value: item.trim(),
        label: item.trim()
      }));

    },

    //初始化img
    initImg() {
      this.$axios.post('/api/single/painting/getImg',{},
          {
            headers: {"Authorization": this.userinfo.token}
          }
      )
          .then((res) => {
            if (res.data.code === 200) {
              this.image = res.data.data;
            }
          })
    },

    //更新余额
    update_money(uId, token) {
      this.$axios.get('/api/user/money/' + uId,
          {
            headers: {"Authorization": token}
          }
      ).then((res) => {
        this.money = res.data.data;
      })
    },

    //返回上一页
    goBack_btn() {
      this.$router.push('/prompt/index')
    },

    //前往支付页
    pay_btn() {
      this.$router.push('/pay')
    },

    //自适应
    resize(a, b) {
      document.getElementById("auto-row-Prompt").style = "height: " + b;
    },
    resize2(a, b) {
      document.getElementById("auto-row-Exclude").style = "height: " + b;
    }

  },
  directives: {  // 使用局部注册指令的方式
    resize: { // 指令的名称
      bind(el, binding) { // el为绑定的元素，binding为绑定给指令的对象
        let width = '', height = '';

        function isReize() {
          const style = document.defaultView.getComputedStyle(el);
          if (width !== style.width || height !== style.height) {
            binding.value(style.width, style.height);  // 关键 绑定函数
          }
          width = style.width;
          height = style.height;
        }

        el.__vueSetInterval__ = setInterval(isReize, 80);
      },
      unbind(el) {
        clearInterval(el.__vueSetInterval__);
      }
    }
  },
  beforeDestroy() {
    document.body.style.height = '100vh';//禁止页面滚动
    document.body.style.overflow = 'auto';//恢复滚动条
  }
}
</script>

<style scoped>
/*头部*/
.my-header {
  background: #e2e5fb;
}

.my-title {
  font-size: 21px;
  vertical-align: middle;
}

/*头部*/
/*按钮*/
.el-button--primary {
  background: transparent !important;
}

/deep/ .el-button--small {
  padding: 8px 10px;
  border-radius: 8px;
}

/deep/ .el-button--primary {
  color: #efba87;
  border-color: #CFB53B;
}


/*按钮*/
/deep/ .el-button--success{
  background-color: rgba(103, 201, 81, 0.66);
  /*#8fbc8f;*/
  border-color: #8fbc8f;
  border-radius: 11px;
  padding: 12px 24px;
}
/*按钮*/
/deep/ .el-button--info{
  background-color: rgba(133, 135, 150, 0.48);
  /*#8fbc8f;*/
  border-color: #8fbc8f;
  border-radius: 11px;
  padding: 12px 24px;
}


.col-label {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
:deep .el-textarea__inner{
  background-color: #f1f3ff;
}
:deep .el-input__inner{
  background-color: #f1f3ff;
}

</style>