<template>
  <div>
  <el-dialog
      title="用户中心"
      :visible.sync="centerDialogVisible"
      width="60%"
      center>
    <div class="user-info">
          <p>昵称:{{ userInfos.nickName }}</p>
          <p>金币:{{ userInfos.money }}</p>
          <p>已邀请:{{ userInfos.inviteCount }}位用户</p>
          <p>邀请码: {{ userInfos.inviteCode }}</p>
          <el-button type="success" round    @click="copyLink">点击复制您的邀请链接</el-button>
        </div>

    <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="centerDialogVisible = false">退出登录</el-button>
    <el-button type="primary" @click="centerDialogVisible = false">返回聊天</el-button>
  </span>
  </el-dialog>


<!--      <div class="user-center">-->
<!--        <h1>用户中心</h1>-->
<!--        <div class="user-info">-->
<!--          <p>昵称:{{ userInfos.nickName }}</p>-->
<!--          <p>金币:{{ userInfos.money }}</p>-->
<!--          <p>已邀请:{{ userInfos.inviteCount }}位用户</p>-->
<!--          <p>邀请码: {{ userInfos.inviteCode }}</p>-->
<!--          <el-button type="primary"  size="small" @click="copyLink">点击复制您的邀请链接</el-button>-->
<!--        </div>-->
<!--      </div>-->


  </div>


</template>

<script>
import router from "@/router";

export default {
  name: "UserCenter.vue",
  data() {
    return {
      userInfos: [],
      invitationLink: '',
      centerDialogVisible: false
    };
  },
  methods: {
    copyLink(){
      var input = document.createElement("input");   // js创建一个input输入框
      input.value = this.invitationLink;  // 将需要复制的文本赋值到创建的input输入框中
      document.body.appendChild(input);    // 将输入框暂时创建到实例里面
      input.select();   // 选中输入框中的内容
      document.execCommand("Copy");   // 执行复制操作
      document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作

      this.$message({
        message: "复制成功,快去分享吧",
        type: "success",
      });
    }
  },


  async created() {
    var userInfo=JSON.parse(localStorage.getItem('userInfo'));
    if(userInfo==null) {
      this.$message({
        message: "请先登录",
        type: "error",
      });
      //3秒后跳转到登录页面
      setTimeout(function () {
        router.push('/');
      }, 1500);
      return;
    }

    await this.$axios.post('/api/user/userinfo', {}, {headers: {'Authorization': userInfo.data.token}}
    ).then((res) => {
      if (res.data.code == 200) {
        this.$message({
          message: "加载信息成功",
          type: "success",
        });

        this.userInfos = res.data.data;
        //TODO 正式环境改前缀
        this.invitationLink = `${window.location.origin}/register?invitationCode=${this.userInfos.inviteCode}`;
      } else {
        //提示显示错误信息
        this.$message({
          message: "加载信息失败," + res.data.msg,
          type: "error",
        });
      }
    });
  }


}
</script>

<style scoped>

</style>