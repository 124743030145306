<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
document.querySelector("meta[name='viewport']")["content"] = "width=device-width,initial-scale=1.0,maximum-scale=1.0,minimum-scale=1.0,user-scalable=no";
export default {
  name: "app",
  components: {
  },

};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
html{



}
body{
  background-color: #e2e5fb;
  margin: 0;
  width: 100vw;
  height: 100vh;
}
</style>
