import Vue from 'vue'
import VueRouter from 'vue-router'
import chatgpt from "../views/chatgpt.vue"
import login from "../views/login.vue"
import pay from "../views/pay.vue"
import feedback from "../views/feedback.vue";
import test1 from "../views/testing/test1.vue";
import test2 from "../views/testing/test2.vue";
import register from "@/views/register";
import UserCenter from "@/components/UserCenter";

import prompt_index from "../views/prompt/index.vue"
import prompt_translate from "../views/prompt/translate.vue"
import prompt_painting from "../views/prompt/painting.vue"
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: login
  },
  {
    path: '/UserCenter',
    name: 'UserCenter',
    component: UserCenter
  },
  {
    path: '/test1',
    name: 'test1',
    component: test1
  },
  {
    path: '/test2',
    name: 'test2',
    component: test2
  },

  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue')
  },

  {
    path: '/chatgpt',
    name: 'chatgpt',
    component: chatgpt
  },

  {
    path: '/pay',
    name: 'pay',
    component: pay
  },

  {
    path: '/feedback',
    name: 'feedback',
    component: feedback
  },
  {
    path: '/register',
    name: 'register',
    component: register
  },
  {
    path: '/prompt/index',
    name: 'prompt_index',
    component: prompt_index
  },
  {
    path: '/prompt/translate',
    name: 'prompt_translate',
    component: prompt_translate
  },
  {
    path: '/prompt/painting',
    name: 'prompt_painting',
    component: prompt_painting
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
