<template>
  <div>
    <header style="background-color: #e3e5fc">
      <h1>Prompt Paradise</h1>
      <!--      <h3 style="font-size: 0.7rem;">点击想使用的卡片进入对应的Prompt(引导词)应用</h3>-->
      <el-row>
        <el-col :span="8">
          <el-button
              type="primary"
              icon="el-icon-coin"
              size="small"
              @click="pay()">{{ money }}
          </el-button>
        </el-col>

        <el-col :span="8">
          <el-button
              type="primary"
              icon="el-icon-user"
              size="small"
              @click="centerDialogVisible = true"
          >个人中心
          </el-button>
        </el-col>

        <el-col :span="8">
          <el-button
              type="primary"
              icon="el-icon-user"
              size="small"
              @click="goto_feedback"
          >建议反馈
          </el-button>
        </el-col>

      </el-row>

      <el-divider><i class="el-icon-s-opportunity"></i></el-divider>
    </header>


    <el-row type="flex" style="flex-wrap:wrap" class="row-bg">

      <el-col :xs="9" :sm="4" :md="4" :offset="2">
        <a href="/chatgpt" style="text-decoration:none;">
          <el-card class="my-card" style="background-color:#d2fbdf">
            <span class="card-title">
              ChatGPT
            </span>
            <el-divider>
              <i class="el-icon-star-off"></i>
            </el-divider>
            <div class=" item text">
              直接进入通用Ai，进行自定义对话。
            </div>
          </el-card>
        </a>
      </el-col>


      <el-col :xs="9" :sm="4" :md="4" :offset="1">
        <a href="/prompt/translate" style="text-decoration:none;">
          <el-card class="my-card" style="background-color: #fdedd5">
            <span class="card-title">专业翻译</span>
            <el-divider>
              <i class="el-icon-star-off"></i>
            </el-divider>
            <div class=" item text">
              专业翻译，支持多种语言。
            </div>
          </el-card>
        </a>
      </el-col>


      <el-col :xs="9" :sm="4" :md="4" :offset="2">
        <a href="/prompt/painting" style="text-decoration:none;">
          <el-card class="my-card" style="background-color: #fcd6db">
            <span class="card-title">Ai绘图</span>
            <el-divider>
              <i class="el-icon-star-off"></i>
            </el-divider>
            <div class=" item text">Ai绘图现已正式上线！
            </div>
          </el-card>
        </a>
      </el-col>


      <el-col :xs="9" :sm="4" :md="4" :offset="1">
        <a style="text-decoration:none;">
          <el-card class="my-card">
            <span class="card-title">待征集</span>
            <el-divider>
              <i class="el-icon-magic-stick"></i>
            </el-divider>
            <div class=" item text">
              把你想要的功能在反馈页留言
            </div>
          </el-card>
        </a>
      </el-col>


    </el-row>

    <el-row type="flex" style="flex-wrap:wrap" class="row-bg">


      <el-col :xs="9" :sm="4" :md="4" :offset="2">
        <a style="text-decoration:none;">
          <el-card class="my-card">
            <span class="card-title">Ai阅读与问答(待定)</span>
            <el-divider>
              <i class="el-icon-magic-stick"></i>
            </el-divider>
            <div class=" item text">
              Word/PDF等格式的文献/文章/文档等
            </div>
          </el-card>
        </a>
      </el-col>


      <el-col :xs="9" :sm="4" :md="4" :offset="1">
        <a style="text-decoration:none;">
          <el-card class="my-card">
            <span class="card-title">沉浸式剧情游戏(待定)</span>
            <el-divider>
              <i class="el-icon-magic-stick"></i>
            </el-divider>
            <div class=" item text">
              设定背景下Ai续写剧情,你来选择剧情分支
            </div>
          </el-card>
        </a>
      </el-col>


    </el-row>

    <el-dialog
        title="个人中心"
        :visible.sync="centerDialogVisible"
        width="72%"
        center>

      <div style="  text-align: initial;">
        <el-tag type="info">昵称:</el-tag>
        {{ userinfo.nickName }}
        <br>
        <el-tag type="info">金币:</el-tag>
        {{ money }}
        <br>
        <el-tag type="info">已邀请:</el-tag>
        {{ userinfo.inviteCount }}位用户
        <br>
        <el-tag type="info">通过邀请累计获得:</el-tag>
        {{ userinfo.inviteCount * 10000 }} 金币
        <br>
        <el-tag type="info">邀请码:</el-tag>
        {{ userinfo.inviteCode }}
        <br>

      </div>
      <p>对方通过邀请链接打开注册会自动填入您的邀请码！双方均可增加1W金币。</p>
      <br>
      <el-button style="margin-bottom: 1vh" type="success" round @click="copyInvitationLink">点击复制您的邀请链接
      </el-button>
      <p>每日签到可获得3000金币喔！</p>

      <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="checkIn()">签到</el-button>
    <el-button type="primary" @click="centerDialogVisible = false">关闭</el-button>
  </span>
    </el-dialog>
  </div>

</template>

<script>
export default {
  name: "index",
  data() {
    return {
      userinfo: [],
      money: '0',
      invitationLink: '',
      centerDialogVisible: true,//用户中心弹窗

      at_wechatWindow:false,//是否在微信浏览器中
      checked: false,
    }
  },
  mounted() {
    //iphone


    if (this.atWechatBrowser()) {
      this.at_wechatWindow = true;
      //alert("这是微信,at_wechatWindow="+this.at_wechatWindow)
    }
    else {
      document.body.style.overflow = 'hidden';//禁止页面滚动
      document.body.style.height = '86vh';//禁止页面滚动
    }

  },
  created() {
    window.vm = this;//将变量全局化,这样浏览器可以查看当前data是什么情况::myData._data
    this.userinfo = JSON.parse(localStorage.getItem('userInfo')).data;
    this.invitationLink = `http://playprompt.fun/register?Code=${this.userinfo.inviteCode}`;
    this.money = this.userinfo.money;
    this.update_money(this.userinfo.id, this.userinfo.token);
    // this.isChecked();

  },
  methods: {
    // isChecked() {
    //   this.$axios.post('/api/user/checkIn/status', {},
    //       {
    //         headers: {"Authorization": this.userinfo.token}
    //       }
    //   )
    //       .then((res) => {
    //         if (res.data.code === 200) {
    //           console.log("签过了")
    //         } else {
    //           this.checkIn();
    //         }
    //       })
    //
    // },
    checkIn() {
      //发起签到请求
      this.$axios.post('/api/user/checkIn/', {},
          {
            headers: {"Authorization": this.userinfo.token}
          }
      )
          .then((res) => {
            if (res.data.code === 200) {
              this.$message({
                message: '签到成功,获得3000金币!',
                type: 'success'
              });

            } else {
              this.$message({
                message: res.data.msg + ',请明天再来签到喔~',
                type: 'error'
              });
            }
          })
    },

    update_money(uId, token) {
      this.$axios.get('/api/user/money/' + uId,
          {
            headers: {"Authorization": token}
          }
      ).then((res) => {
        this.money = res.data.data;
      })
    },


    //跳转支付页面
    pay() {
      this.$router.push('/pay');
    },

    //跳转反馈页面
    goto_feedback() {
      this.$router.push('/feedback');
    },

    //一键复制邀请链接
    copyInvitationLink() {
      var input = document.createElement("input");   // js创建一个input输入框
      input.value = this.invitationLink;  // 将需要复制的文本赋值到创建的input输入框中
      document.body.appendChild(input);    // 将输入框暂时创建到实例里面
      input.select();   // 选中输入框中的内容
      document.execCommand("Copy");   // 执行复制操作
      document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作

      this.$message({
        message: "复制成功,快去分享吧",
        type: "success",
      });
    },

    atWechatBrowser() {
      let ua = navigator.userAgent.toLowerCase();
      return /micromessenger/.test(ua) ? true : false;
    },


  },
  beforeDestroy() {
    document.body.style.height = '100vh';//禁止页面滚动
    document.body.style.overflow = 'auto';//恢复滚动条
  }

}
</script>

<style scoped>
/*按钮*/
.el-button--primary {
  background: transparent !important;
}

/deep/ .el-button--small {
  padding: 4px 5px;
  border-radius: 5px;
}

/deep/ .el-button--primary {
  color: #efba87;
  border-color: #CFB53B;
}

/deep/ .el-button--success {
  border-color: #ee7878;
}

/*按钮*/


/*dialog*/
/deep/ .el-dialog {
  height: 65vh;
}

/deep/ .el-dialog--center .el-dialog__body {
  text-align: center;
  padding: 0px 17px 0px;
}


/*dialog*/


.row-bg {
  padding: 10px 0;
  background-color: #e3e5fc;
}

.words {
  font-size: 16px;
}

.button {
  padding: 6px;
}

.card-img {
  height: 9vh;
  width: 100%;
}

.card-content {

  height: 2vh;
  width: 100%;
}

/*尺寸响应式*/

@media screen and (min-width: 200px) and (max-width: 500px) {
  /deep/ .el-divider--horizontal {
    margin: 10px 0;
  }

  .my-card {
    padding: 6px;
    margin-bottom: 10px;
    height: 19vh;
    border-radius: 18px;
  }
}

@media screen and (min-width: 501px) and (max-width: 1281px) {
  .my-card {
    padding: 6px;
    margin-bottom: 10px;
    height: 23vh;
    border-radius: 18px;
  }
}

@media screen and (min-width: 1282px) and (max-width: 9999px) {
  .my-card {
    padding: 6px;
    margin-bottom: 10px;
    height: 23vh;
    border-radius: 18px;
  }
}


.card-title {
  font-weight: bold;
}

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

/deep/ .el-divider__text {
  background-color: rgba(0, 0, 0, 0);
}
</style>
<style>@media (max-width: 720px) {
  .box {
    width: 320px !important;
  }
}</style>
