<template>
  <div>
    <header>
      <h1>购买金币</h1>
      <p style="font-size: 0.8rem">邀请注册成功后双方均可增加10000金币,多邀多得</p>
      <p style="font-size: 0.8rem">请前往个人中心获取邀请码和邀请链接</p>
      <el-divider><i class="el-icon-coin"></i></el-divider>

      <el-input
          type="text"
          v-model="cdkey"
          style="width: 70%"
          placeholder="请输入CD-KEY激活码"
          @keyup.enter.native="Keyup"
          prefix-icon="el-icon-coin"
          clearable show-word-limit>
      </el-input>
        <br>
      <el-button type="success" @click="submitpay(cdkey)"  class="payBtn">充值</el-button>

      <el-divider><i class="el-icon-coin"></i></el-divider>
    </header>


      <el-row type="flex" style="flex-wrap:wrap;background-color: #e3e5fc" class="row-bg">

        <el-col :xs="9" :sm="4" :md="4" :offset="2">
          <el-card class="my-card">
            <el-row class="card-img" type="flex" justify="center" align="middle">
              <img :src="token_img_1" class="image">
            </el-row>
            <el-row  class="card-content">
              <el-row class="words">1W 金币</el-row>
              <el-row>
                <el-button  type="warning" class="button" @click="buy(token_url_1)">购买CDKey</el-button>
              </el-row>
            </el-row>
          </el-card>
        </el-col>


        <el-col :xs="9" :sm="4" :md="4" :offset="1">
          <el-card class="my-card">
            <el-row class="card-img" type="flex" justify="center" align="middle">
              <img :src="token_img_2" class="image">
            </el-row>
            <el-row  class="card-content">
              <el-row class="words">10W 金币</el-row>
              <el-row>
                <el-button  type="warning" class="button" @click="buy(token_url_2)">购买CDKey</el-button>
              </el-row>
            </el-row>
          </el-card>
        </el-col>


        <el-col :xs="9" :sm="4" :md="4" :offset="2">
          <el-card class="my-card">
            <el-row class="card-img" type="flex" justify="center" align="middle">
              <img :src="token_img_3" class="image">
            </el-row>
            <el-row  class="card-content">
              <el-row class="words">50W 金币</el-row>
              <el-row>
                <el-button  type="warning" class="button" @click="buy(token_url_3)">购买CDKey</el-button>
              </el-row>
            </el-row>
          </el-card>
        </el-col>


        <el-col :xs="9" :sm="4" :md="4" :offset="1">
          <el-card class="my-card">
            <el-row class="card-img" type="flex" justify="center" align="middle">
              <img :src="token_img_4" class="image">
            </el-row>
            <el-row  class="card-content">
              <el-row class="words">100W 金币</el-row>
              <el-row>
                <el-button  type="warning" class="button" @click="buy(token_url_4)">购买CDKey</el-button>
              </el-row>
            </el-row>
          </el-card>
        </el-col>

      </el-row>
    </div>

</template>

<script>
import router from '@/router'
export default {
  name: "",
  data() {
    return {
      userInfo:null,
      cdkey: "",
      token_img_1: require("../assets/1.png"),
      token_url_1: "http://cdkey.playprompt.fun/shop/?id=13",

      token_img_2: require("../assets/2.png"),
      token_url_2: "http://cdkey.playprompt.fun/shop/?id=14",

      token_img_3: require("../assets/3.png"),
      token_url_3: "http://cdkey.playprompt.fun/shop/?id=15",

      token_img_4: require("../assets/4.png"),
      token_url_4: "http://cdkey.playprompt.fun/shop/?id=16",
    };
  },
  mounted() {

    document.body.style.overflow = 'hidden';//恢复滚动条
  },
  created() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
  },
  methods: {
    buy(url){
      window.location.href = url;
    },
    Keyup() {
      this.submit(this.cdkey);
    },

    submitpay(cdkey) {
      if (!(cdkey.length === 25 || cdkey.length === 30 ||cdkey =="老板娘")) {
        this.$message({
          message: "CD-KEY激活码输入不正确",
          type: "error",
        });
        return;
      }

      if(this.userInfo==null){
        this.$message({
          message: "请先登录",
          type: "error",
        });
        //3秒后跳转到登录页面
        setTimeout(function () {
          router.push('/');
        }, 1000);
        return;
      }

      const token = this.userInfo.data.token;

      //todo 发布时删除输出
      // console.log(token)
      //todo 发布时删除输出

      this.$axios.get('/api/cdkey/' + cdkey,
          {
            headers: {"Authorization": token}
          }
      ).then((res) => {
        //todo 发布时删除输出
        // console.log(res);
        //todo 发布时删除输出

        if (res.data.code === 200) {
          this.$message({
            message: "充值成功",
            type: "success",
          });
        } else {
          this.$message({
            message: "充值失败,"+ res.data.msg,
            type: "error",
          });
        }
      });
    },
  },
  beforeDestroy() {
    document.body.style.height = '100vh';//禁止页面滚动
    document.body.style.overflow = 'auto';//恢复滚动条
  }
}
</script>
<style scoped>
.el-card{
  background-color: #e2e5fb;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.words{
  font-size:1rem;
}

.card-content{

  height:2vh;
  width: 100%;
}

/deep/ .el-divider__text {
  background-color: rgba(0, 0, 0, 0);
}

/deep/ .el-button--success{
  background-color: rgba(103, 201, 81, 0.66);
  border-color: #8fbc8f;
  border-radius: 11px;
}
/deep/ .el-button--warning{
  background-color: rgba(211, 121, 45, 0.5);
  border-radius: 11px;
}

:deep .el-input__inner{
  background-color: #f1f3ff;
}


/*尺寸响应式*/
/*手机*/
@media screen and (min-width: 200px) and (max-width: 550px) {
  /deep/ .el-divider--horizontal{
    margin: 10px 0;
  }

  .my-card{
    padding: 2vw;
    margin-bottom: 3vh;
    height: 23vh;
  }

  .image {
    width: 12vh;
    height: 12vh;
    max-width: 100%;
    max-height: 100%;
  }

  .card-img{
    height:12vh;
    width: 100%;
  }
  .button {
    padding: 2vw;
    margin-top: 1vh;
  }


}

/*ipad*/
@media screen and (min-width: 551px) and (max-width:1281px) {
  .my-card{
    padding: 1vh;
    margin-bottom: 10px;
    height: 36vh;
  }

  .image {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
  .card-img{
    height:20vh;
    width: 100%;
  }
  .button {
    padding: 1vw;
    margin-top: 2vw;
  }

}

/*电脑*/
@media screen and (min-width: 1282px) and (max-width:9999px) {
  .my-card{
    padding: 5px;
    margin-top: 7vh;
    height: 45vh;
  }

  .image {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
  .card-img{
    height:30vh;
    width: 100%;
  }
  .button {
    padding: 1vw;
    margin-top: 3vh;
  }
}


/*ipad*/
@media screen and (min-height: 960px) and (max-width:9999px) {
  .my-card{
    margin-top: 10vh;
    height: 25vh;
    width: 16vw;
  }

  .image {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
  .card-img{
    height:16vh;
    width: 100%;
  }
  .button {
    padding: 1vw;
    margin-top: 2vh;
  }
.payBtn{
  margin-top: 3vh;
  margin-bottom: 3vh;
  width: 30vw;
}
}
</style>