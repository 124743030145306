<template>

  <div >

    <!--头部-->
    <el-row>
      <!-- 头部 7vh -->
      <el-row class="my-header" style="height: 7vh" type="flex" align="middle">

        <el-col :offset="1" :span="5">
          <el-button
              type="primary"
              icon="el-icon-arrow-left"
              size="small"
              @click="goBack_btn">
            返回首页
          </el-button>
        </el-col>

        <el-col :span="12">
          <label class="my-title">
            Prompt Paradise
          </label>
        </el-col>

        <el-col :span="5">
          <el-button
              type="primary"
              icon="el-icon-coin"
              size="small"
              @click="pay()">{{ money }}
          </el-button>
        </el-col>

<!--        <el-col :span="6">-->
<!--          <el-button-->
<!--              type="primary"-->
<!--              icon="el-icon-coin"-->
<!--              size="small"-->
<!--              @click="pay()">{{ money }}-->
<!--          </el-button>-->
<!--        </el-col>-->

<!--        <el-col :span="12">-->
<!--          <label class="my-title">-->
<!--            Prompt Paradise-->
<!--          </label>-->
<!--        </el-col>-->

<!--        <el-col :span="4">-->
<!--          <el-button-->
<!--              type="primary"-->
<!--              icon="el-icon-user"-->
<!--              size="small"-->
<!--              @click="centerDialogVisible = true"-->
<!--          >用户中心-->
<!--          </el-button>-->
<!--        </el-col>-->


      </el-row>

    </el-row>


    <!--chat区-->
    <el-row :class="['my-chatarea',at_wechatWindow?'chatHeightHandle_wechat_window':'chatHeightHandle']">

      <!--TODO: 待修改loading-->
      <div v-loading="loading"
           element-loading-text="组织语言中..."
           element-loading-spinner="el-icon-loading"
           element-loading-background="rgba(0, 0, 0, 0.5)"
           style="background-color:#e2e5fb ">
        <!--TODO: 待修改loading-->

        <el-main :class="['my-chatarea-border',at_wechatWindow?'chatHeightHandle_wechat_window':'chatHeightHandle']"  ref="main">
          <el-row :gutter="0" v-for="(item,index) in showlist" :key="index">
            <el-col :span="24" class="my-chatarea-overflow">

              <el-row>
                <el-divider v-if="item.author===0" content-position="right">
                  <label>{{ userinfo.nickName }}</label>
                  <i class="el-icon-user" style="background-color: #FFDAB9"></i>
                </el-divider>

                <el-divider v-if="item.author===1" content-position="left">
                  <i class="el-icon-mobile-phone" style="background-color: #FFDAB9"></i>
                  chatGPT
                </el-divider>
              </el-row>

              <div v-if="item.author===0" class="my-cardarea-user">
                <el-card class="box-card my-card-text" shadow="always">
                  {{ item.question }}
                </el-card>
              </div>

              <div v-if="item.author===1" class="my-cardarea-gpt">
                <el-card class="box-card my-card-text"

                         shadow="always"
                         ref="cards"
                         :data-id="index"
                         :key="`${index}-${item.showMarkdown}`">


                  <markdown-it-vue v-if="item.showMarkdown" class="md-body my-card-text" :content="item.answer"/>
                  <div v-if="!item.showMarkdown"> {{ item.answer }}</div>

                  <el-button style="float: right; padding: 3px 0;color: white" type="text" @click="copyAnswer(item.answer)">
                    复制<i class="el-icon-document-copy"></i>
                  </el-button>
                </el-card>
              </div>
              <br>

            </el-col>
          </el-row>

        </el-main>
      </div>
    </el-row>


    <!--输入区-->
    <el-row>

      <el-row type="flex" align="middle" style="height: 3vh;background-color: #e2e5fb">
        <el-divider class="el-divider-1"><i class="el-icon-star-off"></i></el-divider>
      </el-row>

      <el-row class="my-methods-area" style="height: 2vh" type="flex" align="middle" justify="center">
<!--        <el-checkbox v-model="stream">流式输出</el-checkbox>-->
<!--        <el-checkbox v-model="open_context" disabled>无上下文</el-checkbox>-->
        <el-button @click="clear" size="small" style=" padding: 3px 0;" type="text">一键清屏</el-button>

        {{ test_output_words }}
      </el-row>



      <!--    输入区↓  height: 14vh;-->

      <el-row type="flex" align="middle" style="background: #e2e5fb;height: 14vh;">
        <!--第一版#80d7cf-->
        <el-col :span="4">
          <el-button
              style="background-color: #ee7878"
              size="large"
              type="success"
              icon="el-icon-service"
              circle
              @click="goto_feedback()">
          </el-button>
        </el-col>

        <!--        :autosize="{ minRows: 3, maxRows: 3 }"-->
        <!--              @focus="inputFocus"-->
        <!--              @blur="inputBlur"-->
        <el-col :span="16"  >
          <el-autocomplete
              v-model="input"
              style="border-radius: 30px;width: 100%;"
              type="textarea"
              @keyup.enter.native="Keyup"
              :rows="4"
              placeholder="输入 / 获取提示句模板,或者直接输入您的提问。"
              :maxlength="2000"
              show-word-limit
              :fetch-suggestions="querySearch"
              :trigger-on-focus="false"
              @select="handleSelect"
          ></el-autocomplete>
        </el-col>

        <el-col :span="4">
          <el-button
              style="background-color: #ee7878"
              size="large"
              type="success"
              icon="el-icon-s-promotion"
              circle
              @click="send_question(input)">
          </el-button>
        </el-col>

      </el-row>


        &nbsp;
      </el-row>




    <el-dialog
        title="用户中心"
        :visible.sync="centerDialogVisible"
        width="70%"
        center>

      <div style="  text-align: initial;">
        <el-tag type="info">昵称:</el-tag>
        {{ userinfo.nickName }}
        <br>
        <el-tag type="info">金币:</el-tag>
        {{ money }}
        <br>
        <el-tag type="info">已邀请:</el-tag>
        {{ userinfo.inviteCount }}位用户
        <br>
        <el-tag type="info">通过邀请累计获得:</el-tag>
        {{ userinfo.inviteCount*10000 }} 金币
        <br>
        <el-tag type="info">邀请码:</el-tag>
        {{ userinfo.inviteCode }}
        <br>

      </div>
      <p>对方通过邀请链接打开注册会自动填入您的邀请码！双方均可增加1W金币。</p>
      <br>
      <el-button style="margin-bottom: 1vh" type="success" round @click="copyInvitationLink">点击复制您的邀请链接
      </el-button>
      <p>每日签到可获得3000金币喔！</p>
      <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="logout()">退出登录</el-button>
    <el-button type="primary" @click="centerDialogVisible = false">返回聊天</el-button>
  </span>
    </el-dialog>

  </div>
</template>

<script>
import UserCenter from "@/components/UserCenter";
import MarkdownItVue from 'markdown-it-vue'
import 'markdown-it-vue/dist/markdown-it-vue.css'

const cardState = {
  showMarkdown: new Set(),
};

export default {
  components: {MarkdownItVue, UserCenter},
  name: "chat",
  mounted() {
    if (this.atWechatBrowser()) {
      this.at_wechatWindow = true;
      //alert("这是微信,at_wechatWindow="+this.at_wechatWindow)
    }
    else {
      document.body.style.overflow = 'hidden';//禁止页面滚动
      document.body.style.height = '90vh';//禁止页面滚动
    }

    window.addEventListener("scroll", this.scrollHandle); //绑定页面滚动事件
    this.activeSlide = false;
    this.userinfo = JSON.parse(localStorage.getItem('userInfo')).data;
    this.update_money(this.userinfo.id, this.userinfo.token);
    this.init_prompt();

    this.get_all_record().then(() => {

      //markdown
      this.intersectionObserver = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => this.handleIntersect(entry, observer));
      }, {threshold: 0.1});

      this.$nextTick(() => {
        const cardElements = this.$refs.cards;
        cardElements.forEach(card => this.intersectionObserver.observe(card.$el));
      });

    });

    //滚动条自动滚动到底部
    this.slide();
    this.activeSlide = true;
  },


  created() {
    window.mydata = this._data;//将变量全局化,这样浏览器可以查看当前data是什么情况::myData._data
    window.vm = this;//将变量全局化,这样浏览器可以查看当前data是什么情况::myData._data

    this.page_init();
    this.sse_connect();

  },

  watch: {
  },

  data() {
    return {
      at_wechatWindow:false,

      activeSlide: false,
      slideTimerIds: [],

      userinfo: [],
      money: '0',

      showlist: [],
      prompt_list: [],
      question_obj: {},
      input: "",

      // timeout: '',
      stream: true, //是否开启流式传输
      // open_context: true, //是否开启上下文

      eventSource: null,
      sse_connect_start: false,
      sse_temp_data: "",
      sse_sending: false,
      test_output_words: 0, //测试sse输出,显示字数

      loading: false,

      cardState: null,
      scrollTimers: {}, // 用于存储监视markdown定时器

      invitationLink: '',
      centerDialogVisible: true,//用户中心弹窗
    };
  },

  methods: {
    querySearch(queryString, cb) {
      var prompts = this.prompt_list;
      prompts = queryString ? prompts.filter(this.createFilter(queryString)) : prompts;
      // 调用 callback 返回建议列表的数据
      cb(prompts);
    },
    createFilter(queryString) {
      return (prompt_list) => {
        return (prompt_list.value.indexOf(queryString) === 0);
      };
    },

    handleSelect(item) {
      this.input = item.content;
    },

    handleIntersect(entry, observer) {
      const isVisible = entry.isIntersecting;
      const index = parseInt(entry.target.dataset.id);
      const item = this.showlist[index];

      if (isVisible) {
        this.scrollTimers[index] = setTimeout(() => {
          item.showMarkdown = true;
        }, 1200);
      } else {
        clearTimeout(this.scrollTimers[index]);
      }
    },


    page_init() {
      var uinfo = localStorage.getItem('userInfo')//TODO:抽取变量
      this.userinfo = JSON.parse(uinfo)

      this.invitationLink = `http://playprompt.fun/register?Code=${this.userinfo.data.inviteCode}`;
      this.money = this.userinfo.data.money;
      var showlist = [];
      var slideTimerIds;

    },

    async sse_connect() {
      var userInfo = JSON.parse(localStorage.getItem('userInfo'));
      var token = userInfo.data.token;

      //本机测试
      //var url = "http://localhost:8081/api/chatRecord/stream/connect/";

      //内网测试
      //var url="http://192.168.0.106:8081/api/chatRecord/stream/connect/";

      //部署
      var url="http://124.223.170.67:8081/api/chatRecord/stream/connect/";


      this.eventSource = new EventSource(url + token, {withCredentials: true});


      this.eventSource.addEventListener("connect", event => {
      });


      this.eventSource.addEventListener("start", event => {
        //todo 发布时删除输出
        // console.log("SSE start:", event);
        //todo 发布时删除输出

        this.sse_sending = true;
      });


      this.eventSource.addEventListener("end", event => {
        //todo 发布时删除输出
        // console.log("SSE end:", event);
        //todo 发布时删除输出

        this.sse_sending = false;

        setTimeout(() => {
          this.showlist[this.showlist.length - 1].showMarkdown = true;
          this.$forceUpdate();
        }, 500);

        //更新用户余额
        this.update_money(userInfo.data.id, token);
      });

      this.eventSource.onComplete = event => {
        //todo 发布时删除输出
        // console.log("SSE complete:", event);
        //todo 发布时删除输出

        // alert("对话结束：onComplete");
        this.eventSource.close();
        this.eventSource = null;
      };

      this.eventSource.onopen = event => {
      };

      this.eventSource.onerror = event => {
      };

      this.eventSource.onmessage = event => {
        //todo 发布时删除输出
        // console.log("SSE message:", event)
        //todo 发布时删除输出

        this.test_output_words++;

        //替换收到的data中的 <&nbsp> 为" "
        this.sse_temp_data = event.data.replace(/<&nbsp>/g, " ");
        //替换收到的data中的\n\n 为" "
        this.sse_temp_data = this.sse_temp_data.replace(/\n\n/g, "");
        this.sse_temp_data = this.sse_temp_data.replace(/<%><%>/g, "\n");
        this.sse_temp_data = this.sse_temp_data.replace(/<%>/g, "\n");

        // this.sse_temp_data = this.sse_temp_data.replace(/\n\n/g, "\n");
        //todo 发布时删除输出
        // console.log(event.data);
        //todo 发布时删除输出

        this.showlist[this.showlist.length - 1].answer += this.sse_temp_data;

        //滑到最下
        this.slide();
        this.activeSlide = true;
      };

    },

    async send_question() {
      //清空测试输出字数
      this.test_output_words = 0;

      //检测是否正在发送
      if (this.sse_sending) {
        this.$message({
          message: '正在生成回复中,请等待回复完成后再试',
          type: 'warning'
        });
        return;
      }


      //检测用户是否登录
      var userInfoTemp = JSON.parse(localStorage.getItem('userInfo'));
      if (userInfoTemp == null) {
        this.$message({
          message: '请先登录',
          type: 'warning'
        });
        //延时2秒跳转到登录页面
        setTimeout(() => {
          this.$router.push("/");
        }, 1500)
        return;
      }

      //获取用户token
      var temptoken = userInfoTemp.data.token;

      //判断输入是否为空
      var tempinput = this.input;
      if (tempinput == null || tempinput == "") {
        this.$message({
          message: '输入内容不能为空',
          type: 'warning'
        });
        //强制退出该函数,不执行后续代码
        return;
      }

      //判断余额是否够
      this.update_money(userInfoTemp.data.id, temptoken);
      if (this.money < tempinput.length) {
        this.$message({
          message: '金币不足该次提问,请点击左上角前往充值',
          type: 'error'
        });
        this.loading = false;
        return;
      }


      //开始提问请求 TODO
      // this.input = ""//清空输入框

      if (this.stream) {
        //console.log("使用流式");
        await this.get_answer_with_stream(tempinput, temptoken);
      } else {
        //console.log("不使用流式");
        await this.get_answer_without_stream(tempinput, temptoken);
      }


    },

    //获取答案-不使用流式
    async get_answer_without_stream(tempinput, temptoken) {
      var question_obj = {
        "question": tempinput,
        "author": 0,
        "id": this.showlist[0] === undefined ? '1' : this.showlist[this.showlist.length - 1].id + 1,
        "answer": ""
      }

      //将用户输入的questionObj添加到showlist中
      this.showlist.push(question_obj);

      //滑到最下
      this.slide();
      this.activeSlide = true;

      //进入loading状态
      this.loading = true;


      await this.$axios.post('/api/chatRecord/chat', {"question": tempinput},
          {
            headers: {"Authorization": temptoken}
          }
      )
          .then((res) => {
            if (res.data.code === 200) {
              this.$message({
                message: '发送成功',
                type: 'success'
              });

              //清除输入框
              this.input = "";
              //退出loading状态
              this.loading = false;
              this.sse_sending = false;

              let gptoutput = res.data.data;

              //todo 发布时删除输出
              // console.log(gptoutput)//打印gpt回复的内容
              //todo 发布时删除输出

              gptoutput.showMarkdown = true;
              //将gpt回复的内容添加到showlist中
              this.showlist.push(gptoutput);

              //滑到最下
              this.slide();
              this.activeSlide = true;

            } else {
              this.$message({
                message: res.data.msg,
                type: '回答失败，请重试或进行反馈'
              });
              this.loading = false;
              this.sse_sending = false;
            }
          })
    },

    //获取答案-使用流式
    async get_answer_with_stream(tempinput, temptoken) {

      //检查连接是否正常
      await this.$axios.post('/api/chatRecord/stream/test', {},
          {
            headers: {
              "Authorization": temptoken
            }
          }
      ).then(async (res) => {
        if (res.data.code === 200) {
          //todo 发布时删除输出
          // console.log("连接测试正常");


        } else if (res.data.code === 1234) {

          //todo 发布时删除输出
          // console.log("连接测试失败");
          //todo 发布时删除输出
          await this.sse_connect();
        }
      })


      var question_obj = {
        "question": tempinput,
        "author": 0,
        "id": this.showlist[0] === undefined ? '1' : this.showlist[this.showlist.length - 1].id + 1,
        "answer": ""
      }

      //将用户输入的questionObj添加到showlist中
      this.showlist.push(question_obj);

      var answer_obj = {
        "question": null,
        "author": 1,
        "id": this.showlist[0] === undefined ? '1' : this.showlist[this.showlist.length - 1].id + 1,
        "answer": " ",
        "showMarkdown": false
      }

      //将answer_obj的空模板添加到showlist中
      this.showlist.push(answer_obj);

      //滑到最下
      this.slide();
      this.activeSlide = true;

      //进入loading状态
      this.loading = true;
      this.sse_sending = true;

      //console.log("发送请求前SSE状态检查:"+this.eventSource.readyState!==2? "连接已断开"+this.eventSource.readyState:"连接正常"+this.eventSource.readyState);
      await this.$axios.post('/api/chatRecord/stream/getanswer', {"question": tempinput},
          {
            headers: {
              "Content-Type": "text/event-stream",
              "Authorization": temptoken
            }
          }
      )
          .then((res) => {
            //如果失败 返回错误信息
            if (res.data.code !== 200) {
              this.$message({
                message: "连接丢失，正在尝试自动重连并发送...",//res.data.msg,
                type: 'error'
              });

              //todo 发布时删除输出
              // console.log("sse请求错误" + res);
              //todo 发布时删除输出

              //退出loading状态
              this.loading = false;
              this.sse_sending = false;
              if (res.data.code == 1234) {

                this.showlist.pop();
                this.showlist.pop();

                //延时4S后重新发送问题
                setTimeout(() => {
                  this.send_question(tempinput)
                }, 2000)


              }
              // return; todo 111
            } else {
              this.$message({
                message: '发送成功',
                type: 'success'
              });

              //todo 发布时删除输出
              // console.log("请求成功:" + res);
              //todo 发布时删除输出

              //清除输入框
              this.input = "";
              //退出loading状态
              this.loading = false;
              this.sse_sending = false;
              this.slide();
              this.activeSlide = true;
            }
          });
    },

    //初始化prompt_list
    init_prompt() {
      this.$axios.get('/api/prompt/getPromptList').then((res) => {
        // console.log(res.data.data)
        this.prompt_list = res.data.data.map((x) => {
          return {
            id: x.id,
            value: x.template,
            content: x.content,
            type: x.type
          };
        });
        this.prompt_list.push({
          id: this.prompt_list.length + 1,
          value: "/待更新...",
          content: "",
          type: 1
        })
      })

    },

    //获取所有历史记录
    get_all_record() {
      var userInfoTemp = JSON.parse(localStorage.getItem('userInfo'));
      var temptoken = userInfoTemp.data.token;
      this.activeSlide = false; //关闭滚动条

      return this.$axios.post('/api/chatRecord/all', {},
          {
            headers: {"Authorization": temptoken}
          }
      )
          .then((res) => {
            if (res.data.code === 200) {
              this.$message({
                message: '获取所有历史记录成功',
                type: 'success'
              });

              this.showlist = res.data.data.reverse()
              //this.showlist中如果有answer属性的项，把answer的内容里的\n替换成1
              this.showlist.forEach((item) => {
                if (item.answer) {
                  item.answer = item.answer.replace(/\n\n/g, "\n");
                  item.answer = item.answer.replace(/\n \n/g, "\n");
                  item.answer = item.answer.replace(/\r\n/g, "\n");
                  // if (item.answer.endsWith("\n")) {
                  //   item.answer = item.answer.substring(0, item.answer.length - 1);
                  // }
                }
              })



              //滚动条自动滚动到底部
              this.slide();
              this.activeSlide = true;
            } else {
              this.$message({
                message: res.data.msg,
                type: 'error'
              });
            }
          })

    },

    //更新余额
    update_money(uId, temptoken) {
      this.$axios.get('/api/user/money/' + uId,
          {
            headers: {"Authorization": temptoken}
          }
      ).then((res) => {
        //console.log("money before update:" + this.money)
        this.money = res.data.data;
        //console.log("money after update:" + this.money)
      })
    },

    //一键复制邀请链接
    copyInvitationLink() {
      var input = document.createElement("input");   // js创建一个input输入框
      input.value = this.invitationLink;  // 将需要复制的文本赋值到创建的input输入框中
      document.body.appendChild(input);    // 将输入框暂时创建到实例里面
      input.select();   // 选中输入框中的内容
      document.execCommand("Copy");   // 执行复制操作
      document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作

      this.$message({
        message: "复制成功,快去分享吧",
        type: "success",
      });
    },

    //一键复制answer内容
    copyAnswer(content) {
      let textarea = document.createElement("textarea");   // js创建一个input输入框
      textarea.value = content+'\n'+'——内容分享自'+this.userinfo.nickName+'\n注册地址'+this.invitationLink;  // 将需要复制的文本赋值到创建的input输入框中
      document.body.appendChild(textarea);    // 将输入框暂时创建到实例里面
      textarea.select();   // 选中输入框中的内容
      document.execCommand("Copy");   // 执行复制操作
      document.body.removeChild(textarea); // 最后删除实例中临时创建的input输入框，完成复制操作
      this.$message({
        message: "复制成功,快去分享吧",
        type: "success",
      });
    },

    //注销
    logout() {
      localStorage.removeItem('userInfo');
      localStorage.removeItem('Authorization');
      this.$message({
        message: "退出成功",
        type: 'success'
      });

      this.$router.push('/');
    },

    //跳转支付页面
    pay() {
      this.$router.push('/pay');
    },

    //跳转反馈页面
    goto_feedback() {
      this.$router.push('/feedback');
    },

    //跳转到首页
    goBack_btn() {
      this.$router.push('/prompt/index')
    },

    //键盘监听
    Keyup() {
      //删除this.input的最后一个换行符
      if (this.input.endsWith("\n")) {
        this.input = this.input.substring(0, this.input.length - 1);
      }
      this.send_question(this.input);
    },

    //滚动条自动滚动到底部
    slide() {
      // 添加鼠标滚轮滚动监听
      window.addEventListener("touchmove", this.wheelScroll);//滚动条滚动时触发mousewheel
      let div = document.getElementsByClassName("el-main");//获取日志区域

      // 添加日志区域点击监听
      div[0].onmousedown = () => {
        // 清除定时器
        this.clearSlideInterval();
        // 移除鼠标滚轮滚动监听
        div[0].onmousedown = null;
      };
      // 添加日志区域点击监听
      this.slideTimerIds.push(setInterval(this.slideTimer, 10, div));
    },
    slideTimer(div) {
      // 滚动到底部时清除定时器
      if (div[0].scrollTop + div[0].clientHeight >= div[0].scrollHeight) {
        this.clearSlideInterval(false);
      } else {
        div[0].scrollTop += 1000;
      }
    },
    wheelScroll(e) {
      e = e || window.event;
      if (e.wheelDelta !== 0) {
        this.clearSlideInterval();
        window.removeEventListener("mousewheel", this.wheelScroll);
      }
    },
    clearSlideInterval(active = true) {
      this.slideTimerIds.forEach((id) => {
        clearInterval(id);
      });
      this.slideTimerIds = [];

      // 手动或自动滚动
      this.activeSlide = active;//
    },

    //一键清除历史聊天记录的显示
     clear(){
        this.showlist = this.showlist.slice(this.showlist.length-2,this.showlist.length);
     },
     atWechatBrowser() {
        let ua = navigator.userAgent.toLowerCase();
        return /micromessenger/.test(ua) ? true : false;
     }

  },

  beforeUnmount(){

  },
  //销毁时关闭sse Todo:存在问题
  beforeDestroy() {
    //1.4
    document.body.style.height = '100vh';//禁止页面滚动
    document.body.style.overflow = 'auto';//恢复滚动条
    window.removeEventListener("mousewheel", this.wheelScroll);
    window.removeEventListener("touchmove", this.wheelScroll);
    this.intersectionObserver.disconnect();

    //关闭SSE

    this.eventSource.close();
    this.eventSource = null;
    //todo:通知后端关闭sse

  }
}
</script>


<style scoped>

:deep .el-textarea__inner{
  background-color: #f1f3ff;
}
/*---------------------------layout----------------------------------*/
/*头部*/
.my-header {
  background: #e2e5fb;
}

.my-title {
  font-size: 21px;
  vertical-align: middle;
}

/*头部*/


/*聊天区*/
/deep/ .el-main {
  padding: 16px;
}

.my-chatarea-border {
  border-left: 1px dashed #d6c67d;
  border-right: 2px dotted #d6c67d;
  background-color: #e2e5fb;
}

.my-cardarea-user {
  float: right;
  width: fit-content;
  padding-left: 20%;
}

.my-cardarea-gpt {
  float: left;
  width: fit-content;
  padding-right: 20%;
}

.my-chatarea-overflow {
  word-wrap: break-word;
}

.box-card {
  max-width: 70vw;
  text-align: left;
  word-wrap: break-word;
  white-space: pre-wrap;
  background-color: #8FBC8F;
}

.my-card-text {
  font-size: 16px;
  color: #e8e9e1;
  text-align: left;
  word-wrap: break-word;
  white-space: pre-wrap;

}

/deep/ .el-card__body {
  padding: 12px;
}

/*聊天区*/


/*输入区*/
/deep/ .el-divider-1 {
  background-color: #CFB53B;
}

/deep/ .el-divider__text {
  padding: 0 0px;
  background-color: #ffdab9;
}

.my-methods-area {
  font-size: 8px;
  background-color: #e2e5fb;
}

/*输入区*/

/*dialog*/
/deep/ .el-dialog {
  height: 65vh;
}

/deep/ .el-dialog--center .el-dialog__body {
  text-align: center;
}

/*dialog*/

/*---------------------------layout----------------------------------*/


/*按钮*/
.el-button--primary {
  background: transparent !important;
}

/deep/ .el-button--small {
  padding: 4px 5px;
  border-radius: 5px;
}

/deep/ .el-button--primary {
  color: #efba87;
  border-color: #CFB53B;
}

/deep/ .el-button--success {
  border-color: #ee7878;
}

/*按钮*/

  /*markdown*/
/deep/ .markdown-body code {
  background-color: rgb(255, 255, 255, 0.3);
}

/deep/ .markdown-body {
  color: inherit;
}

/deep/ .markdown-body blockquote, .markdown-body details, .markdown-body dl, .markdown-body ol, .markdown-body p, .markdown-body pre, .markdown-body table, .markdown-body ul {
  margin-top: 0;
  margin-bottom: 0px;
}

/deep/ p {
  display: inline-block !important;
}

/*markdown*/


/*尺寸响应式*/
@media only screen and (min-width: 800px) {
  .my-chatarea {
    padding-left: 22%;
    padding-right: 22%;
    background-color: #e2e5fb;
  }
}

@media screen and (min-width: 800px) and (max-width: 1300px) {
  .my-chatarea {
    padding-left: 10%;
    padding-right: 10%;
    background-color: #e2e5fb;
  }
}

@media screen and (min-width: 1300px) and (max-width: 2000px) {
  .my-chatarea {
    padding-left: 10vw;
    padding-right: 10%;
    background-color: #e2e5fb;
  }
}

/*尺寸响应式*/
@media screen and (min-width: 300px) and (max-width: 500px) {
  .chatHeightHandle {
    height: 61vh;
  }
  .chatHeightHandle_wechat_window {
    height: 72vh;
  }

}
@media screen and (min-width: 501px) and (max-width:1281px) {
  .chatHeightHandle {
    height: 65vh;
  }
  .chatHeightHandle_wechat_window {
    height: 72vh;
  }
}
@media screen and (min-width: 1282px) and (max-width:9999px) {
  .chatHeightHandle {
    height: 71vh;
  }
  .chatHeightHandle_wechat_window {
    height: 72vh;
  }
}

</style>
