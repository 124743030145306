<template>
  <div>

    <el-row>
      <el-header style="height: 19vh;background-color: #e3e5fc" >
        <el-row type="flex" justify="center" align="middle" style="height: 18vh;font-size: 2rem">
          Prompt Paradise
        </el-row>

      </el-header>
    </el-row>


    <el-row style="background-color: #e3e5fc">
      <el-col :xs="0" :sm="4" :md="6" :lg="6" :xl="8">&nbsp;</el-col>

      <el-col :xs="24" :sm="16" :md="12" :lg="12" :xl="8">
        <el-main style="height: 65vh;background-color: #e3e5fc">


          <el-form label-width="60px" :model="ruleForm"
                   ref="ruleForm" :rules="rules"
                   style="background-color: #e3e5fc">
            <!------------------------------------------用户名--------------------------------------------->
            <el-row class="row">
              <el-col :span="21" :offset="1">
                <el-form-item label="用户名" prop="userName">
                  <el-input
                      v-model="ruleForm.userName"
                      maxlength="25"
                      placeholder="请输入用户名"
                      prefix-icon='el-icon-user-solid'
                      clearable show-word-limit>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <!---------------------------------------------------------------------------------------------->


            <!--            <el-row style="height: 1vh;background-color: #f8eeee">&#45;&#45;&#45;&#45;&#45;&#45;</el-row>-->
            <!------------------------------------------密码---------------------------------------------->
            <el-row class="row">
              <el-col :span="21" :offset="1">
                <el-form-item label="密码" prop="password">
                  <el-input
                      v-model="ruleForm.password"
                      placeholder="请输入密码"
                      prefix-icon='el-icon-lock'
                      maxlength="20"
                      show-password
                      clearable>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <!---------------------------------------------------------------------------------------------->
            <!--            <el-row style="height: 2vh;background-color: #f8eeee">&#45;&#45;&#45;&#45;&#45;&#45;</el-row>-->
            <!---------------------------------------------------------------------------------------------->
            <el-row class="row">
              <el-col :span="21" :offset="1">
                <el-form-item label="昵称" prop="nickName">
                  <el-input
                      v-model="ruleForm.nickName"
                      placeholder="请输入昵称"
                      maxlength="20"
                      prefix-icon='el-icon-user'
                      clearable show-word-limit>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <!---------------------------------------------------------------------------------------------->
            <!--            <el-row style="height: 2vh;background-color: #f8eeee">&#45;&#45;&#45;&#45;&#45;&#45;</el-row>-->
            <!---------------------------------------------------------------------------------------------->
            <el-row class="row">
              <el-col :span="21" :offset="1">
                <el-form-item label="手机号" prop="phone">
                  <el-input
                      v-model="ruleForm.phone"
                      maxlength="11"
                      placeholder="请输入手机号"
                      prefix-icon='el-icon-mobile-phone'
                      clearable show-word-limit>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <!---------------------------------------------------------------------------------------------->
            <!--            <el-row style="height: 2vh;background-color: #f8eeee">&#45;&#45;&#45;&#45;&#45;&#45;</el-row>-->
            <!---------------------------------------------------------------------------------------------->
            <el-row class="row">
              <el-col :span="13" :offset="1">
                <el-form-item label="验证码" prop="code">
                  <el-input
                      @keyup.enter.native="Keyup"
                      v-model="ruleForm.code"
                      maxlength="6"
                      placeholder="请输入验证码"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            <!---------------------------------------------------------------------------------------------->
              <el-col :span="6" :offset="1">
                <el-button :loading=codeFlag @click="sendCode()" v-if="codeFlag">重新发送{{count}}s</el-button>
                <el-button  @click="sendCode()" v-else>发送验证码</el-button>
              </el-col>
            </el-row>
            <!---------------------------------------------------------------------------------------------->
            <el-row class="row">
              <el-col :span="21" :offset="1">
                <el-form-item label="邀请码" prop="inviteCode">
                  <el-input
                      :readonly="show"
                      maxlength="4"
                      v-model="ruleForm.inviteCode"
                      placeholder="双方都可加金币,也可不填"
                      prefix-icon='el-icon-coin'
                      clearable show-word-limit>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <!---------------------------------------------------------------------------------------------->
            <el-form-item class="my-register-button" type="flex" justify="center">
              <el-button style="width: 70%"  @click="RegisterFrom('ruleForm')">注册</el-button>
            </el-form-item>
            <!---------------------------------------------------------------------------------------------->

            <!--            </el-row>-->

            <!---------------------------------------------------------------------------------------------->
          </el-form>

        </el-main>
      </el-col>

      <el-col :xs="0" :sm="4" :md="6" :lg="6" :xl="8">&nbsp;</el-col>
    </el-row>


    <el-row>
      <footer style="height: 14vh;background-color: #e3e5fd"></footer>
    </el-row>
  </div>
</template>

<script>

export default {
  name: "register",
  mounted() {
    document.body.style.overflow = 'hidden';

    const urlParams = new URLSearchParams(window.location.search);
    const invitationCode = urlParams.get('Code')!=null?urlParams.get('Code'):(urlParams.get('invitationCode')!=null?urlParams.get('invitationCode'):null);


    if (invitationCode) {
      this.ruleForm.inviteCode = invitationCode;
      this.show = true;
    }
  },
  data() {
    return {
      codeFlag: false,
      count:0,
      timer: null,
      show: false,

      ruleForm: {
        userName: '',
        password: '',
        nickName: '',
        phone: '',
        code: '',
        inviteCode: null,
      },

      rules: {
        userName: [
          {message: '请输入用户名', trigger: 'blur'},
          {min: 4, max: 25, message: '长度为4-25', trigger: 'blur'}
        ],
        password: [
          {message: '请输入密码', trigger: 'blur'},
          {min: 8, max: 20, message: '长度为8-20', trigger: 'blur'}
        ],
        nickName: [
          {message: '请输入昵称', trigger: 'blur'},
          {min: 2, max: 20, message: '长度为2-20', trigger: 'blur'}
        ],
        phone: [
          {message: '请输入手机号', trigger: 'blur'},
          {min: 11, max: 11, message: '长度为11', trigger: 'blur'}
        ],
        code: [
          {message: '请输入验证码', trigger: 'blur'},
          {min: 6, max: 6, message: '长度为6', trigger: 'blur'}
        ],
      }
    };
  },
  methods: {
    Keyup() {
      this.RegisterFrom('ruleForm');
    },
    sendCode() {
      if(this.ruleForm.phone === null || this.ruleForm.phone === ''){
        this.$message({
          message: '请输入手机号',
          type: 'warning'
        });
        return;
      }
      let TIME_COUNT = 90;
      this.$axios.post('/api/register/sendcode/'+this.ruleForm.phone ).then((res) => {
        if (res.data.code === 200) {
          //todo 发布时删除输出
          // console.log(res.data.data);
          //todo 发布时删除输出

          this.$message({
            message: '发送成功！(如未收到，90秒后可重新尝试发送)',
            type: 'success'
          });
          this.codeFlag = true;

          if (!this.timer) {
            this.count = TIME_COUNT;
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--;
              } else {
                this.codeFlag = false;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          }

        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          });
        }
      });


    },

    async RegisterFrom(formName) {
      if(this.ruleForm.phone === null || this.ruleForm.phone === ''){
        this.$message({
          message: '手机号不能为空',
          type: 'warning'
        });
        return;
      };
      if(this.ruleForm.code === null || this.ruleForm.code === ''){
        this.$message({
          message: '验证码不能为空',
          type: 'warning'
        });
        return;
      };
      if(this.ruleForm.userName === null || this.ruleForm.userName === ''){
        this.$message({
          message: '用户名不能为空',
          type: 'warning'
        });
        return;
      };
      if(this.ruleForm.password === null || this.ruleForm.password === ''){
        this.$message({
          message: '密码不能为空',
          type: 'warning'
        });
        return;
      };
      if(this.ruleForm.nickName === null || this.ruleForm.nickName === ''){
        this.$message({
          message: '昵称不能为空',
          type: 'warning'
        });
        return;
      };

      //待检查
     this.$refs[formName].validate(async (valid) => {
       if (valid) {
         if (this.ruleForm.inviteCode === '')
         {
           this.ruleForm.inviteCode = null;
         }
         await this.$axios.post('/api/register/', this.ruleForm).then((res) => {
           //todo 发布时删除输出
           // console.log(res.data);
           //todo 发布时删除输出

           //确保请求成功再做后续处理
           //同步处理res
           if (res.data.code === 200) {
             this.$message({
               message: '注册成功！',
               type: 'success'
             });

             //延时1S后跳转
             setTimeout(() => {
               this.$router.push('/');
             }, 1000);
           } else {
             this.$message({
               message: res.data.msg,
               type: 'error'
             });
           }
         });
       } else {
         this.$message({
           message: '请检查输入项',
           type: 'warning'
         });
         return false;
       }
     });
    }
  },
  beforeDestroy() {
    document.body.style.overflow = 'auto';//恢复滚动条
  }

}
</script>

<style scoped>

.row {
  background-color: #e3e5fc;
  height: 9vh
}
/deep/ .el-form-item {
  margin-bottom: 0px;
}

/deep/ .el-button {
  padding: 12px 8px;
  background-color: rgba(103, 201, 81, 0.66);
  color: white;
}
:deep .el-input__inner{
  background-color: #f1f3ff;
}

.my-register-button {
  margin-right: 60px;
}
</style>