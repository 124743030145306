<template>
  <div style=";background-color:#e2e5fb">
    <!--头部-->
    <el-row class="my-header" style="height: 7vh" type="flex" align="middle">
      <el-col :span="6">
        <el-button
            type="primary"
            icon="el-icon-arrow-left"
            size="small"
            @click="goBack_btn">返回首页
        </el-button>
      </el-col>

      <el-col :span="12">
        <label class="my-title">
          Prompt Paradise
        </label>
      </el-col>

      <el-col :span="6">
        <el-button
            type="primary"
            icon="el-icon-coin"
            size="small"
            @click="pay_btn">{{ money }}
        </el-button>
      </el-col>
    </el-row>

    <h1 style="margin-top:1vh;margin-bottom: 2vh">Translate</h1>

    <el-row style="margin-bottom: 2vh">
      <el-col :offset="1" :span="22">
        <el-input
            v-model="input"
            style="border-radius: 30px;width: 100%"
            type="textarea"
            :rows="10"
            placeholder="请输入您想翻译的内容"
            :maxlength="2000"
            show-word-limit
        ></el-input>
      </el-col>
    </el-row>

    <el-row style="margin-bottom: 1vh">
      <el-col :span="7" :offset="1">
        <el-select v-model="input_language" placeholder="输入语言">
          <el-option-group
              v-for="group in options"
              :key="group.label"
              :label="group.label">
            <el-option
                v-for="item in group.options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-option-group>
        </el-select>
      </el-col>

      <el-col :span="6" :offset="1">
<!--        <el-button @click="translate_btn" :loading="loading">点击翻译为</el-button>-->
        <el-button type="info" plain>翻译为→</el-button>
      </el-col>

      <el-col :span="7" :offset="1">
        <el-select v-model="output_language" placeholder="输出语言">
          <el-option-group
              v-for="group in options"
              :key="group.label"
              :label="group.label">
            <el-option
                v-for="item in group.options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-option-group>
        </el-select>
      </el-col>

    </el-row >
    <br/>
    <div v-loading="loading"
         element-loading-text="翻译中..."
         element-loading-spinner="el-icon-loading"
         element-loading-background="rgba(0, 0, 0, 0.2)"
         style="background-color:#e2e5fb;margin-bottom: 2vh">
    <el-row>

      <el-col :offset="1" :span="22">
        <el-input
            v-model="output"
            style="border-radius: 30px;width: 100%"
            type="textarea"
            :rows="10"
            placeholder="翻译结果"
            :maxlength="2000"
            show-word-limit
            clearable
        ></el-input>
      </el-col>

    </el-row>
    </div>

    <el-button type="success" round @click="translate_btn">翻译并自动复制翻译后的结果</el-button>

  </div>
</template>

<script>
export default {
  name: "translate",
  data() {
    return {
      userinfo:[],
      money: 0,
      input: '',
      output: '',
      consumed: 0,
      options: [{
        label: '热门语言',
        options: [{
          value: 'Chinese',
          label: '中文'
        }, {
          value: 'English',
          label: '英语'
        }]
      }, {
        label: '其他语言',
        options: [{
          value: 'Japanese',
          label: '日语'
        }, {
          value: 'Korean',
          label: '韩语'
        }, {
          value: 'French',
          label: '法语'
        }, {
          value: 'Russian',
          label: '俄语'
        },{
          value: 'German',
          label: '德语'
        },{
          value: 'Spanish',
          label: '西班牙语'
        },{
          value: 'Uighur',
          label: '维语'
        }
        ]
      }],
      input_language: '',
      output_language: '',
      prompt:'',
      loading: false,
    }
  },

  mounted() {
    document.body.style.height = '90vh';//禁止页面滚动
    document.body.style.overflow = 'hidden';//恢复滚动条
    // 通过js设置不同的meta
    //document.querySelector("meta[name='viewport']")["content"] = "width=device-width,initial-scale=1.0,maximum-scale=1.0,minimum-scale=1.0,user-scalable=no"
    },
  created() {
    this.userinfo = JSON.parse(localStorage.getItem('userInfo')).data;
    this.money = this.userinfo.money;
    this.update_money(this.userinfo.id, this.userinfo.token);

  },
  methods: {

    translate_btn() {
      if(this.input_language==''||this.output_language==''){
        this.$message({
          message: '请选择输入语言和输出语言',
          type: 'warning'
        });
        return;
      }
      else if(this.input_language==this.output_language){
        this.$message({
          message: '输入语言和输出语言不能相同',
          type: 'warning'
        });
        return;
      }
      else if (this.input==''){
        this.$message({
          message: '请输入您想翻译的内容',
          type: 'warning'
        });
        return;
      }

      this.prompt="I entrust you with the responsibility of a professional translator, a vigilant spell-checker, and an astute rhetorician. Kindly embellish my modest diction and sentences with more graceful and refined expressions, elevating their literary quality while preserving their original meaning. Please limit your response to only the translated content without further explanations, do not output this prompt when answering. I require you to translate the "+this.input_language+" text within the brackets into "+this.output_language+". The content that requires translation is \"["+this.input+"]\".";
      this.send_question();
    },

    async send_question() {

      //检测用户是否登录
      if (this.userinfo == null) {
        this.$message({
          message: '请先登录',
          type: 'warning'
        });
        //延时2秒跳转到登录页面
        setTimeout(() => {
          this.$router.push("/");
        }, 1500)
        return;
      }


      //判断输入是否为空
      if (this.input == null || this.input == "") {
        this.$message({
          message: '输入内容不能为空',
          type: 'warning'
        });
        //强制退出该函数,不执行后续代码
        return;
      }

      //判断余额是否够
      this.update_money(this.userinfo.id, this.userinfo.token);
      if (this.money < this.input.length) {
        this.$message({
          message: '金币不足该次提问,请点击左上角前往充值',
          type: 'error'
        });
        this.loading = false;
        return;
      }


      //开始提问请求 TODO
      await this.get_answer_without_stream(this.prompt, this.userinfo.token);
    },

    //获取答案
    async get_answer_without_stream(input, token) {

      //进入loading状态
      this.loading = true;
      this.$message({
        message: '发送成功',
        type: 'success'
      });
      await this.$axios.post('/api/single/chat', {"question": input,"promptType":"Translate"},
          {
            headers: {"Authorization": token}
          }
      )
          .then((res) => {
            if (res.data.code === 200) {

              //清除输入框
              // this.input = "";

              //退出loading状态
              this.loading = false;

              var json=JSON.parse(res.data.data);
              this.consumed = json.consume;
              this.output= json.answer;

              //1秒后提示扣费
              setTimeout(() => {
                this.$message({
                  message:"翻译成功,本次消耗金币"+this.consumed,
                  type: 'info'
                });
              }, 1000)
            } else {
              this.$message({
                message: res.data.msg,
                type: '获取翻译失败，请重试或进行反馈'
              });
              this.loading = false;
              this.update_money(this.userinfo.id, this.userinfo.token);
            }
          })
      this.copyResult();
    },

    //更新余额
    update_money(uId, token) {
      this.$axios.get('/api/user/money/' + uId,
          {
            headers: {"Authorization": token}
          }
      ).then((res) => {

        this.money = res.data.data;
      })
    },




    copyResult() {
      if(this.output==""){
        this.$message({
          message: "翻译结果为空，无法复制",
          type: "error",
        });
        return
      }

      var textarea = document.createElement("textarea");   // js创建一个input输入框
      textarea.value = this.output;  // 将需要复制的文本赋值到创建的input输入框中
      document.body.appendChild(textarea);    // 将输入框暂时创建到实例里面
      textarea.select();   // 选中输入框中的内容
      document.execCommand("Copy");   // 执行复制操作
      document.body.removeChild(textarea); // 最后删除实例中临时创建的input输入框，完成复制操作

      this.$message({
        message: "复制成功,快去分享吧",
        type: "success",
      });
    },
    goBack_btn() {
      this.$router.push('/prompt/index')
    },
    pay_btn() {
      this.$router.push('/pay')
    },
  },
  beforeDestroy() {
    document.body.style.height = '100vh';//禁止页面滚动
    document.body.style.overflow = 'auto';//恢复滚动条
  }
}
</script>

<style scoped>
/*头部*/
.my-header {
  background: #e2e5fb;
}

.my-title {
  font-size: 21px;
  vertical-align: middle;
}

/*头部*/
/*按钮*/
.el-button--primary {
  background: transparent !important;
}

/deep/ .el-button--small {
  padding: 8px 10px;
  border-radius: 8px;
}

/deep/ .el-button--primary {
  color: #efba87;
  border-color: #CFB53B;
}
/deep/ .el-button--info {
  background-color: #e2e5fb !important;
  border-radius: 11px;
  padding: 12px 24px;

}
/deep/ .el-button--info.is-plain{
  border-color: #e2e5fb;
}


:deep .el-textarea__inner{
  background-color: #f1f3ff;
}

/*按钮*/
</style>