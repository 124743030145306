<template>

  <div style="background-color: #e3e5fc">
    <!--占位栅格1-->
    <el-row type="flex" align="middle">
      <el-col style="height: 12vh ;background-color: #e3e5fc">

      </el-col>
    </el-row>


    <!--2-->
    <el-row type="flex" align="middle" style="background-color: #e3e5fc;height: 22vh">
      <el-col :span="4"></el-col>

      <el-col :span="16">
        <label style="font-size:35px;vertical-align: middle;font-family: Microsoft YaHei">Prompt Paradise</label>
      </el-col>

      <el-col :span="4"></el-col>
    </el-row>

    <!--占位栅格3-->
    <el-row type="flex" align="middle">
      <el-col style="height: 5vh ;background-color: #e3e5fc">

      </el-col>
    </el-row>

    <!-- 4 form-->
    <el-row style="height: 24vh">
      <el-col :xs="3" :sm="6" :md="7" :lg="7" :xl="7">&nbsp;</el-col>
      <el-col :xs="18" :sm="12" :md="10" :lg="10" :xl="10">
        <loginform></loginform>
      </el-col>
      <el-col :xs="3" :sm="6" :md="7" :lg="7" :xl="7">&nbsp;</el-col>
    </el-row>
    <!--占位栅格9-->
    <!--  <el-row type="flex" align="middle">-->
    <!--    <el-col style="height: 10vh ;background-color: #dcd1bd">-->

    <!--    </el-col>-->
    <!--  </el-row>-->

    <!--占位栅格10-->
    <el-row type="flex" align="middle">
      <el-col style="height: 22vh ;background-color:#e3e5fc">

      </el-col>
    </el-row>

    <!--11-->
    <el-row>
      <el-col style="height: 2vh;background-color: #e3e5fc;">

        <a target="_blank"
           href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32050902101532"
           title="点击跳转到管理局"
           class="myfont"
           style="text-decoration:none;color:rgb(122,117,117)">

          <img :src="beian">

          <a class="myfont">苏公网安备 32050902101532号&nbsp;&nbsp;&nbsp;&nbsp;</a>
          <img :src="beian2">
          <a href="https://beian.miit.gov.cn/"
             target="_blank"
             title="点击跳转到管理局"
             class="myfont"
             style="text-decoration:none;color:rgb(122,117,117)"
          > 苏ICP备2023011107号-1</a>
        </a>


      </el-col>
    </el-row>


  </div>

</template>
<script>
import Loginform from "@/components/loginform";

export default {
  components: {Loginform},
  data() {
    return {
      userinfo: [],
      beian: require('../assets/beian.png'),
      beian2: require('../assets/beian2.png'),

      at_wechatWindow:false,//是否在微信浏览器中
    }
  },
  mounted() {
    if (this.atWechatBrowser()) {
      this.at_wechatWindow = true;
      alert("这是微信,at_wechatWindow="+this.at_wechatWindow)
    }
    else {
      document.body.style.overflow = 'hidden';//禁止页面滚动
      document.body.style.height = '90vh';//禁止页面滚动
    }
  },
  created() {
    this.userinfo = JSON.parse(localStorage.getItem('userInfo')).data;
    if (this.userinfo) {
      this.checkLogin();
    }
  },
  methods: {

    checkLogin() {
      this.$axios.post('/api/loginCheck/', {},
          {
            headers: {"Authorization": this.userinfo.token}
          }
      )
          .then((res) => {
            if (res.data.code === 200) {
              this.$message({
                message: '登录信息有效，自动登录成功',
                type: 'success'
              });
              //1秒后跳转index页
              setTimeout(() => {
                this.$router.push({path: '/prompt/index'})
              }, 1500)

            } else {
              this.$message({
                message: '登录信息已过期，请重新登录',
                type: 'info'
              });
            }
          })


    },

    atWechatBrowser() {
      let ua = navigator.userAgent.toLowerCase();
      return /micromessenger/.test(ua) ? true : false;
    }
  },
  beforeDestroy() {
    document.body.style.height = '100vh';//禁止页面滚动
    document.body.style.overflow = 'auto';//恢复滚动条
  }
}
</script>
<style>
.myfont {
  font-size: 10px;
}

@media (min-width: 640px) {
  .myfont {
    font-size: 11px;
  }
}

@media (min-width: 800px) {
  .myfont {
    font-size: 13px;
  }
}

@media (min-width: 1000px) {
  .myfont {
    font-size: 15px;
  }
}

</style>