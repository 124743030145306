<template>
  <div>
    <p style="height: 20vh"></p>
    <h1>反馈建议</h1>
    <p style="height: 2vh"></p>
    <el-main>
      <el-row type="flex" justify="center" align="middle" >

        <el-input
            style="max-width: 70%"
            v-model="input"
            type="textarea"
            placeholder="请写下您的建议,也可以联系QQ:1765739901"
            @keyup.enter.native="Keyup"
            :autosize="{ minRows: 5, maxRows: 10 }"
            show-word-limit
        ></el-input>

      </el-row>
      <br>   <br>   <br>
      <el-row type="flex" justify="center" align="middle" >
        <el-col>
          <el-button @click="send_feedback(input)" type="success">提交反馈</el-button>
        </el-col>
        <br>
        <el-col>
          <el-button @click="goback()" type="success">返回首页</el-button>
        </el-col>
      </el-row>
    </el-main>
  </div>
</template>

<script>
import router from '@/router'


export default {
  name: "feedback",
  mounted() {
    document.body.style.overflow = 'hidden';//恢复滚动条
  },

  created() {
    this.userInfo=JSON.parse(localStorage.getItem('userInfo'));
    if(this.userInfo==null){
      this.$message({
        message: "请先登录",
        type: "error",
      });
      //3秒后跳转到登录页面
      setTimeout(function () {
        router.push('/');
      }, 1500);
      return;
    }
  },
  methods: {
    Keyup() {
      this.send_feedback(this.input);
    },
    goback() {
      router.push({path: '/prompt/index'});
    },
    send_feedback(input) {


      if(this.userInfo==null){
        this.$message({
          message: "请先登录",
          type: "error",
        });
        //3秒后跳转到登录页面
        setTimeout(function () {
          router.push('/');
        }, 1500);
        return;
      }
      const token = this.userInfo.data.token;

      // 发送反馈到后端接口 /api/feedback
      this.$axios.post('/api/feedback/',
          {
            "userId": this.userInfo.data.id,
            "feedback": input,
            "createTime": this.nowTime()
          },
          {
            headers: {"Authorization": token}
          }
      )
          .then((response) => {

            //todo 发布时删除输出
            // console.log(response);
            //todo 发布时删除输出

            if (response.data.code == 200) {
              this.$message({
                message: '反馈成功',
                type: 'success'
              });

              //延时2秒后跳转
              setTimeout(function () {
                router.push('/chatgpt');
              }, 2000);

            } else {
              this.$message({
                message: '反馈失败',
                type: 'error'
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });

    }
  },
  data() {
    return {
      input: '',
      userInfo: null,
    }
  },
  beforeDestroy() {
    document.body.style.height = '100vh';//禁止页面滚动
    document.body.style.overflow = 'auto';//恢复滚动条
  }
}
</script>

<style scoped>

/deep/ .el-button--success{
  background-color: rgba(103, 201, 81, 0.66);
  border-color: #8fbc8f;
  border-radius: 11px;
}

:deep .el-input__inner{
  background-color: #f1f3ff;
}

</style>